import abTests from './endpoints/ab-tests';
import abmAudiences from './endpoints/abm-audiences';
import accessControl from './endpoints/access-control';
import accounts from './endpoints/accounts';
import activityAudiences from './endpoints/activity-audiences';
import agencies from './endpoints/agencies';
import allAudiences from './endpoints/all-audiences';
import auth from './endpoints/auth';
import bundles from './endpoints/bundles';
import campaigns from './endpoints/campaigns';
import carriers from './endpoints/carriers';
import categories from './endpoints/categories';
import cities from './endpoints/cities';
import countries from './endpoints/countries';
import creatives from './endpoints/creatives';
import dashboard from './endpoints/dashboard';
import deals from './endpoints/deals';
import deviceLanguages from './endpoints/device-languages';
import deviceModels from './endpoints/device-models';
import devices from './endpoints/devices';
import domainList from './endpoints/domain-list';
import eventsMappings from './endpoints/events-mappings';
import exchanges from './endpoints/exchanges';
import folders from './endpoints/folders';
import geolocations from './endpoints/geolocations';
import interfaceConfigs from './endpoints/interface-configs';
import inventories from './endpoints/inventories';
import ipRanges from './endpoints/ip-ranges';
import lines from './endpoints/lines';
import lists from './endpoints/lists';
import myAudiences from './endpoints/my-audiences';
import notificationProfiles from './endpoints/notification-profiles';
import osVersions from './endpoints/os-versions';
import prebidAudiences from './endpoints/prebid-audiences';
import regions from './endpoints/regions';
import reports from './endpoints/reports';
import segments from './endpoints/segments';
import skadVersions from './endpoints/skad-versions';
import thirdPartyAudiences from './endpoints/third-party-audiences';
import trackingGroups from './endpoints/tracking-groups';
import userProfileSettings from './endpoints/user-profile-settings';
import users from './endpoints/users';
import zipcodes from './endpoints/zipcodes';

export default {
  auth,
  eventsMappings,
  lists,
  trackingGroups,
  activityAudiences,
  campaigns,
  lines,
  prebidAudiences,
  creatives,
  folders,
  categories,
  countries,
  cities,
  exchanges,
  allAudiences,
  devices,
  carriers,
  skadVersions,
  deviceModels,
  osVersions,
  deviceLanguages,
  inventories,
  bundles,
  ipRanges,
  deals,
  zipcodes,
  regions,
  geolocations,
  accounts,
  users,
  agencies,
  myAudiences,
  abTests,
  thirdPartyAudiences,
  segments,
  notificationProfiles,
  interfaceConfigs,
  reports,
  userProfileSettings,
  abmAudiences,
  domainList,
  accessControl,
  dashboard,
};
