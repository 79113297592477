import axios from 'services/api/axios';
import { Dimension, Metrics } from '../types/dashboard';

export default {
  getDimensionsList: async (queryString?: string): Promise<Dimension[]> => {
    const response = await axios.get(queryString ? `/dashboard/dimensions?${queryString}` : '/dashboard/dimensions');

    return response.data;
  },
  getMetrics: async (queryString?: string): Promise<{ current: Metrics[] }> => {
    const response = await axios.get(queryString ? `/dashboard/metrics?${queryString}` : '/dashboard/metrics');

    return response.data;
  },
};
