import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// material-ui
import { Button, Stack } from '@mui/material';
// libs
import { Field, Formik } from 'formik';
// components
import { Autocomplete } from 'components';
import { campaignStatusOptions, geoTypeOptions } from './helpers';
// icons
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
// styles
import { StyledForm, StyledIconButton, StyledPopover } from './styled';
// types
import { TableFilterProps } from './types';

const TableFilter: React.FC<TableFilterProps> = ({ column, onFilterChange }) => {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const columnFilterValue = column.getFilterValue();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = useCallback(
    (values: any) => {
      onFilterChange?.(values);

      switch (column.id) {
        case 'type': {
          column.setFilterValue(values.type);
          handleClose();
          return;
        }
        case 'status': {
          column.setFilterValue(values.status);
          handleClose();
          return;
        }
        case 'calculatedStatus': {
          column.setFilterValue(values.calculatedStatus);
          handleClose();
          return;
        }
      }
    },
    [column, onFilterChange],
  );

  const filterFields = useMemo(
    () => ({
      name: <div>name field</div>,
      status: (
        <Field
          multiple
          name='status'
          component={Autocomplete}
          placeholder={t('Type to search')}
          options={campaignStatusOptions}
        />
      ),
      calculatedStatus: (
        <Field
          multiple
          name='calculatedStatus'
          component={Autocomplete}
          placeholder={t('Type to search')}
          options={campaignStatusOptions}
        />
      ),
      type: (
        <Field
          multiple
          name='type'
          component={Autocomplete}
          placeholder={t('Type to search')}
          options={geoTypeOptions}
        />
      ),
    }),
    [t],
  );

  return (
    <>
      <StyledIconButton disableRipple onClick={handleClick}>
        <FilterIcon />
      </StyledIconButton>

      <StyledPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ status: columnFilterValue, calculatedStatus: columnFilterValue, type: columnFilterValue }}
        >
          <StyledForm>
            {filterFields[column.id as keyof typeof filterFields]}

            <Stack justifyContent='center' gap={1}>
              <Button
                disableRipple
                variant='outlined'
                sx={{ width: '100%', height: 40, fontSize: 14 }}
                onClick={handleClose}
              >
                {t('Cancel', { ns: 'common' })}
              </Button>

              <Button
                disableRipple
                type='submit'
                variant='contained'
                color='primary'
                sx={{ width: '100%', height: 40, fontSize: 14 }}
              >
                {t('OK', { ns: 'common' })}
              </Button>
            </Stack>
          </StyledForm>
        </Formik>
      </StyledPopover>
    </>
  );
};

export default TableFilter;
