import axios from 'services/api/axios';
import {
  Inventory,
  InventoryCSVRequestData,
  InventoryResponseData,
  InventoryStringRequestData,
  NewInventoryCreateRequestBody,
  NewInventoryCreateResponse,
} from '../types/inventories';
import { ListItem } from '../types/lists';
import { Pagination } from '../types/pagination';

export default {
  get: async (id: number, queryString?: string): Promise<Pagination<Inventory>> => {
    const response = await axios.get(`/lists/${id}/inventories?${queryString}`);

    return response.data;
  },
  newGet: async (id: number, queryString?: string): Promise<Pagination<Inventory>> => {
    const response = await axios.get(`/lists/${id}/inventories?${queryString}`);

    return response.data;
  },
  getListWithoutPagination: async (id: number): Promise<Pagination<Inventory>> => {
    const response = await axios.get(`/lists/${id}/inventories?$limit=10000`);

    return response.data;
  },
  createFromString: async (id: ListItem['id'], data: InventoryStringRequestData): Promise<InventoryResponseData> => {
    const response = await axios.post(`/lists/${id}/inventories-upload/string`, data);

    return response.data;
  },

  newCreate: async (id: ListItem['id'], data: NewInventoryCreateRequestBody): Promise<NewInventoryCreateResponse> => {
    const response = await axios.post(`/lists/${id}/inventories`, data);

    return response.data;
  },

  newDelete: async (id: number, inventoryIds: number[]): Promise<InventoryResponseData> => {
    const response = await axios.delete(
      `/lists/${id}/inventories?${inventoryIds.map((id) => `inventoryIds=${id}`).join('&')}`,
    );

    return response.data;
  },

  newCreateFromCSV: async (id: ListItem['id'], formData: FormData): Promise<NewInventoryCreateResponse> => {
    const response = await axios.post(`/lists/${id}/inventories-upload`, formData);

    return response.data;
  },

  downloadCSV: async (id: ListItem['id']): Promise<any> => {
    const response = await axios.get(`/lists/${id}/inventories-download`);

    return response.data;
  },

  createFromCSV: async (id: ListItem['id'], data: InventoryCSVRequestData): Promise<InventoryResponseData> => {
    const response = await axios.post(`/lists/${id}/inventories-upload/csv`, data);

    return response.data;
  },
  delete: async (id: number, queryString?: string): Promise<void> => {
    const response = await axios.delete(`/lists/${id}/inventories?${queryString}`);

    return response.data;
  },
};
